import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { User } from '@dinbog/models';
import { SpinnerIcon } from '@dinbog/ui';
import { useNotify, useUser } from '../../hooks';
import { useViewProfile } from '../../hooks/api/profiles';
import {
  Layout,
  TalentProfile,
  FanProfile,
  PrivateProfileContent,
  ProfileInfoCard,
  BusinessProfile,
  BlockedProfileContent,
} from '../../components';

function OtherProfilePage() {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const { slug } = router.query;
  const notify = useNotify();
  const { t } = useTranslation();
  const [viewedUser, setViewedUser] = React.useState(undefined);

  React.useEffect(() => {
    if (slug === (user?.currentUser?.user as User)?.slug) {
      router.push('/profile');
    }
  }, [router, user]);

  /* find user by slug */
  const viewProfileMutation = useViewProfile();

  const findUserBySlug = async () => {
    try {
      const data = await viewProfileMutation.mutateAsync({
        from: (user?.currentUser?.user as User)?._id as string,
        to: slug as string,
        search: false,
      });
      if (data?.item) {
        setViewedUser(data);
      }
    } catch (err) {
      setViewedUser(null);
      // return notify(err.response.data.message, 'error');
    }
  };

  React.useEffect(() => {
    if (user) {
      findUserBySlug();
    }
  }, [user, slug]);

  if (viewedUser === undefined) {
    return (
      <div className="w-full h-[70vh] flex  opacity-70 z-30">
        <SpinnerIcon className="m-auto w-24 h-24 text-gray-200 animate-spin  fill-primary-500" />
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (viewedUser === null) {
    return (
      <Layout horizontalPaddingSize="small">
        <div className="w-full h-full flex flex-col items-center justify-center gap-16">
          <img src="/images/fallback/error.png" className="h-1/2" alt="error" />
          <div className="text-center">
            <p className="text-2xl font-semibold mb-4">
              {t('pageError.title')}
            </p>
            <a className="text-xl text-primary-500" href="/">
              {t('pageError.text')}
            </a>
          </div>
        </div>
      </Layout>
    );
  }

  if (viewedUser?.youAreBlocked)
    return (
      <Layout className="bg-background-50">
        <div className="space-y-12">
          <ProfileInfoCard
            user={{ profile: viewedUser?.item, _id: viewedUser?.item?.user }}
            hideDescription
            block={{ to: viewedUser?.blocked, from: viewedUser?.youAreBlocked }}
            findUserBySlug={findUserBySlug}
          />
          <div className="px-12">
            <BlockedProfileContent me />
          </div>
        </div>
      </Layout>
    );

  if (viewedUser?.blocked)
    return (
      <Layout className="bg-background-50">
        <div className="space-y-12">
          <ProfileInfoCard
            user={{ profile: viewedUser?.item, _id: viewedUser?.item?.user }}
            hideDescription
            block={{ to: viewedUser?.blocked, from: viewedUser?.youAreBlocked }}
            findUserBySlug={findUserBySlug}
          />
          <div className="px-12">
            <BlockedProfileContent />
          </div>
        </div>
      </Layout>
    );

  if (!viewedUser?.canView)
    return (
      <Layout className="bg-background-50">
        <div className="space-y-12">
          <ProfileInfoCard
            user={{ profile: viewedUser?.item, _id: viewedUser?.item?.user }}
            hideDescription
            findUserBySlug={findUserBySlug}
          />
          <div className="px-12">
            <PrivateProfileContent />
          </div>
        </div>
      </Layout>
    );

  if (
    viewedUser?.item?.type === 'person' &&
    viewedUser?.item?.categories?.length > 0
  )
    return (
      <Layout className="bg-background-50">
        <TalentProfile
          user={{
            profile: viewedUser?.item,
            _id: viewedUser?.item?.user,
            relations: {
              follow: viewedUser?.follow,
              connection: viewedUser?.connection,
            },
          }}
          findUserBySlug={findUserBySlug}
        />
      </Layout>
    );
  if (
    viewedUser?.item?.type === 'person' &&
    viewedUser?.item?.categories?.length === 0
  )
    return (
      <Layout className="bg-background-50">
        <FanProfile
          user={{
            profile: viewedUser?.item,
            _id: viewedUser?.item?.user,
            relations: {
              follow: viewedUser?.follow,
              connection: viewedUser?.connection,
            },
          }}
          findUserBySlug={findUserBySlug}
        />
      </Layout>
    );
  if (viewedUser?.item?.type === 'business')
    return (
      <Layout className="bg-background-50">
        <BusinessProfile
          user={{
            profile: viewedUser?.item,
            _id: viewedUser?.item?.user,
            relations: {
              follow: viewedUser?.follow,
              connection: viewedUser?.connection,
            },
          }}
          findUserBySlug={findUserBySlug}
        />
      </Layout>
    );
  return (
    <Layout horizontalPaddingSize="small">
      <div className="w-full h-full flex flex-col items-center justify-center gap-16">
        <img src="/images/fallback/error.png" className="h-1/2" alt="error" />
        <div className="text-center">
          <p className="text-2xl font-semibold mb-4">{t('pageError.title')}</p>
          <a className="text-xl text-primary-500" href="/">
            {t('pageError.text')}
          </a>
        </div>
      </div>
    </Layout>
  );
}

OtherProfilePage.auth = true;

export default OtherProfilePage;
